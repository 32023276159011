/*************************************************************************
 *
 * CONFIDENTIAL
 * __________________
 *
 * [2019] - [2020] Budee Inc
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Budee Inc and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Budee Inc
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Budee Inc.
 */

import * as React from 'react'

import { Select, Spin } from 'antd'

import { SelectProps } from 'antd/lib/select'
import axios from 'axios'
import { classCtx } from '../styles/index'
import { debounce } from 'lodash'
import { tryNice } from 'try-nice'

type BrandSearcherProp = SelectProps<string>
type BrandOption = {
  _id: string
  name: string
}
export const BrandSearcher: React.FC<BrandSearcherProp> = (props) => {
  const [options, setOptions] = React.useState<BrandOption[]>([])
  const [loading, setLoading] = React.useState<boolean>(false)

  const searchForBrand = debounce(async (value: string) => {
    if (value.length > 3) {
      setLoading(true)

      const [result] = await tryNice(() =>
        axios.get('https://api.budee.org/brands/public', {
          params: {
            search: value
          }
        })
      )

      setOptions(result?.data || [])
      setLoading(false)
    }
  }, 500)

  return (
    <Select
      {...props}
      showSearch
      showArrow={false}
      filterOption={false}
      className={classCtx('brand-searcher', props.className)}
      onSearch={searchForBrand}
      loading={loading}
      placeholder='Find brand'
      notFoundContent={loading ? <Spin size='small' spinning /> : null}
    >
      {options.map((option) => (
        <Select.Option key={option._id} value={option._id}>
          {option.name}
        </Select.Option>
      ))}
    </Select>
  )
}

export default BrandSearcher
