/**
 * Check if color is light
 *
 * @export
 * @param {string} color
 * @returns {boolean}
 */
export function isLightColor(color: string): boolean {
  // Variables for red, green, blue values
  let r, g, b
  let valuatingColor

  // Check the format of the color, HEX or RGB?
  if (color.match(/^rgb/)) {
    // If HEX --> store the red, green, blue values in separate variables
    //ts-ignore
    valuatingColor = color.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
    )

    r = color[1]
    g = color[2]
    b = color[3]
  } else {
    // If RGB --> Convert it to HEX: http://gist.github.com/983661
    if (color.length < 5) {
      valuatingColor = +('0x' + color.slice(1).replace(/./g, '$&$&'))
    }

    r = valuatingColor >> 16
    g = (valuatingColor >> 8) & 255
    b = valuatingColor & 255
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b))

  // Using the HSP value, determine whether the color is light or dark
  return hsp > 127.5
}
