import './styles.css'

import * as React from 'react'

import {
  Button,
  Col,
  Collapse,
  Divider,
  Form,
  Input,
  InputNumber,
  Layout,
  Row,
  Switch,
  Typography
} from 'antd'
import { compact, omit } from 'lodash'

import { BrandSearcher } from 'components/brands.searcher'
import { BreedSearcher } from 'components/breed.searcher'
import { CategoriesSearcher } from 'components/categories.searcher'
import { ColorPicker } from 'components/color.picker'
import { classCtx } from 'styles/index'
import querystring from 'qs'

export const HomePage: React.FC = () => {
  const [form] = Form.useForm()
  const [queryStr, setQueryStr] = React.useState<string>()

  const onGenerate = (values: any) => {
    setQueryStr(querystring.stringify(values))
  }

  const getDemoLink = () => {
    if (typeof window !== 'undefined' && querystring) {
      return `${window.location.origin}/run?${queryStr}`
    }

    return null
  }

  const demoLink = getDemoLink()
  return (
    <Layout className={classCtx('home')}>
      <Layout.Header
        className={classCtx('home-header', 'flex-row', 'flex-center')}
      >
        <div className={classCtx('home-logo-container')}>
          <img
            src='https://budee.org/static/site/logo.png'
            className={classCtx('home-logo')}
          />
        </div>
        <Typography.Title level={2} className={classCtx('home-title')}>
          Brand Plug Generator
        </Typography.Title>
      </Layout.Header>
      <Layout.Content className={classCtx('home-content')}>
        <Divider />
        <div
          className={classCtx(
            'home-content-body',
            'max-page-width',
            'flex-column'
          )}
        >
          <Row gutter={[20, 10]} justify='center' align='middle'>
            <Col md={24} xs={24}>
              <div
                className={classCtx(
                  'home-form-container',
                  'flex-center',
                  'flex-column'
                )}
              >
                <Form
                  className={classCtx('home-form')}
                  layout='vertical'
                  form={form}
                  onFinish={onGenerate}
                >
                  <Row gutter={[10, 10]}>
                    <Col xs={24} md={24}>
                      <Form.Item
                        className={classCtx('home-form-brand')}
                        name='brand'
                        label='Display Brand (Logo Only)'
                        rules={[{required: true, message:'Required'}]}
                      >
                        <BrandSearcher />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={24}>
                      <Form.Item
                        className={classCtx('home-form-site')}
                        name='site'
                        label='Site'
                        rules={[
                          { required: true, message: 'Site is required' }
                        ]}
                      >
                        <Input
                          allowClear
                          placeholder='Website to inject into'
                          className={classCtx('home-form-site-input')}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={24}>
                      <Form.Item
                        className={classCtx('home-form-title')}
                        name='title'
                        label='Shop Button Title'
                      >
                        <Input placeholder='Shop Delivery' />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item
                        className={classCtx('home-form-color')}
                        name='color'
                        label='Button Color'
                      >
                        <ColorPicker />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item
                        initialValue='#1890ff'
                        className={classCtx('home-form-color')}
                        name='primaryColor'
                        label='Primary Color'
                      >
                        <ColorPicker />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={24}>
                      <Collapse defaultActiveKey='2'>
                        <Collapse.Panel key='1' header='Button Position'>
                          <Row gutter={[10, 10]}>
                            <Col xs={12} md={6}>
                              <Form.Item
                                className={classCtx('home-form-style-left')}
                                label='Left'
                                name={['style', 'left']}
                              >
                                <InputNumber />
                              </Form.Item>
                            </Col>
                            <Col xs={12} md={6}>
                              <Form.Item
                                className={classCtx('home-form-style-right')}
                                label='Right'
                                name={['style', 'right']}
                              >
                                <InputNumber />
                              </Form.Item>
                            </Col>
                            <Col xs={12} md={6}>
                              <Form.Item
                                className={classCtx('home-form-style-top')}
                                label='Top'
                                name={['style', 'top']}
                              >
                                <InputNumber />
                              </Form.Item>
                            </Col>
                            <Col xs={12} md={6}>
                              <Form.Item
                                className={classCtx('home-form-style-bottom')}
                                label='Bottom'
                                name={['style', 'bottom']}
                              >
                                <InputNumber />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Collapse.Panel>
                        <Collapse.Panel key='2' header='Filters'>
                          <Row gutter={[10, 10]}>
                            <Col xs={24} md={24}>
                              <Form.Item
                                name={['filter', 'brand']}
                                label='Brand'
                              >
                                <BrandSearcher mode='multiple' />
                              </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                              <Form.Item
                                className={classCtx('home-form-breed')}
                                name={['filter', 'breed']}
                                label='Strain Type'
                              >
                                <BreedSearcher />
                              </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                              <Form.Item
                                className={classCtx('home-form-category')}
                                name={['filter', 'category']}
                                label='Categories'
                              >
                                <CategoriesSearcher />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Collapse.Panel>
                        <Collapse.Panel key='3' header='Other'>
                          <Row gutter={[10, 10]}>
                            <Col xs={24} md={12}>
                              <Form.Item
                                className={classCtx('home-form-open-shop')}
                                name='openShop'
                                label='Open on enter'
                              >
                                <Switch />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Collapse.Panel>
                      </Collapse>
                    </Col>
                    <Col xs={24} md={24}>
                      <Divider />
                      <Form.Item>
                        <Button
                          block
                          className={classCtx('home-form-submit-btn')}
                          htmlType='submit'
                          type='primary'
                          shape='round'
                          size='large'
                        >
                          Generate
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Col>
            {queryStr && (
              <Col md={24} xs={24}>
                <div
                  style={{ overflow: 'hidden' }}
                  className={classCtx(
                    'home-result',
                    'flex-column',
                    'flex-center'
                  )}
                >
                  <div className={classCtx('home-result-demo', 'flex-column')}>
                    <Typography.Text strong>Demo link</Typography.Text>
                    <Typography.Text copyable={{ text: demoLink || '' }}>
                      <a target='_blank' href={demoLink || ''}>
                        {demoLink}
                      </a>
                    </Typography.Text>
                  </div>
                  <Divider />
                  <pre>
                    {(() => {
                      const values = form.getFieldsValue()
                      return (
                        <Typography.Paragraph copyable>
                          {compact([
                            '<script src="https://plug.budee.org/bundle.js" type="text/javascript"></script>',
                            '<script type="text/javascript">',
                            '  Tyche.initialize(' +
                              JSON.stringify(omit(values, 'site'), null, 2) +
                              ')',
                            '</script>'
                          ]).join('\n')}
                        </Typography.Paragraph>
                      )
                    })()}
                  </pre>
                </div>
              </Col>
            )}
          </Row>
        </div>
      </Layout.Content>
    </Layout>
  )
}

export default HomePage
