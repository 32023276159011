/*************************************************************************
 *
 * CONFIDENTIAL
 * __________________
 *
 * [2019] - [2020] Budee Inc
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Budee Inc and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Budee Inc
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Budee Inc.
 */

import { Select } from 'antd'
import { SelectProps } from 'antd/lib/select'
import * as React from 'react'
import { classCtx } from 'styles/index'

type BreedSearcherProp = SelectProps<string>

export const BreedSearcher: React.FC<BreedSearcherProp> = (props) => {
  return (
    <Select
      {...props}
      showSearch
      showArrow
      filterOption={false}
      className={classCtx('breed-searcher', props.className)}
      placeholder='Filter by breed'
    >
      {['cbd', 'indica', 'sativa', 'hybrid'].map((breed) => (
        <Select.Option key={breed} value={breed}>
          {breed.toUpperCase()}
        </Select.Option>
      ))}
    </Select>
  )
}

export default BreedSearcher
