import { Button, Popover } from 'antd'
import { isLightColor } from 'lib/color'
import * as React from 'react'
import { ChromePicker, ColorResult } from 'react-color'
import classCtx from 'styles/index'

type ColorPickerProp = {
  value?: string | undefined
  onChange?: (value: string) => any
}

export const ColorPicker: React.FC<ColorPickerProp> = ({ value, onChange }) => {
  const [color, setColor] = React.useState<string | undefined>(value)

  const handleChange = (change: ColorResult) => {
    if (onChange) {
      onChange(change.hex)
    } else {
      setColor(change.hex)
    }
  }

  React.useEffect(() => {
    setColor(value)
  }, [value])

  const displayColor = color || '#e36daa'
  return (
    <Popover
      trigger='hover'
      title={null}
      overlayClassName={classCtx('color-picker')}
      content={
        <ChromePicker color={displayColor} onChangeComplete={handleChange} />
      }
    >
      <Button
        block
        color={displayColor}
        style={{
          backgroundColor: displayColor,
          borderColor: displayColor,
          color: isLightColor(displayColor) ? '#000' : '#fff'
        }}
      >
        {displayColor}
      </Button>
    </Popover>
  )
}

export default ColorPicker
