/*************************************************************************
 *
 * CONFIDENTIAL
 * __________________
 *
 * [2019] - [2020] Budee Inc
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Budee Inc and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Budee Inc
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Budee Inc.
 */

import * as React from 'react'

import { Select, Spin } from 'antd'

import { SelectProps } from 'antd/lib/select'
import axios from 'axios'
import { classCtx } from '../styles'
import { startCase } from 'lodash'
import { tryNice } from 'try-nice'

type CategoriesSearcherProp = SelectProps<string>

type CategoriesOption = {
  _id: string
  name: string
}

export const CategoriesSearcher: React.FC<CategoriesSearcherProp> = (props) => {
  const [options, setOptions] = React.useState<CategoriesOption[]>([])
  const [loading, setLoading] = React.useState<boolean>(false)

  const getCategories = async () => {
    setLoading(true)
    const [result] = await tryNice(() =>
      axios.get('https://api.budee.org/produts/public/categories')
    )
    setOptions(result?.data || [])
    setLoading(false)
  }

  React.useEffect(() => {
    getCategories()
  }, [])

  return (
    <Select
      {...props}
      showSearch
      showArrow
      filterOption={false}
      loading={loading}
      placeholder='Filter by Category'
      className={classCtx('categories-searcher', props.className)}
      notFoundContent={loading ? <Spin size='small' spinning /> : null}
    >
      {options.map((item) => (
        <Select.Option key={item._id} value={item._id}>
          {startCase(item.name)}
        </Select.Option>
      ))}
    </Select>
  )
}

export default CategoriesSearcher
